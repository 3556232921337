
import { Component, Prop, Vue } from 'vue-property-decorator';
import contrastChecker from 'color-contrast-checker';

@Component
export default class ColorField extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  public value: string;

  @Prop({
    type: Boolean,
    required: true,
  })
  public wcag: boolean;

  @Prop({
    type: String,
    required: true,
  })
  public label: string;

  public isContrastOk = true;

  public get color() {
    return this.value;
  }

  public set color(val) {
    this.isContrastOk =
      new RegExp('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$').test(val) &&
      new contrastChecker().isLevelCustom(val, '#fff', 4.5);
    this.$emit('input', val);
  }
}
